var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "b-tabs",
          { attrs: { animated: "" } },
          [
            _c(
              "b-tab-item",
              { attrs: { label: "Privacy" } },
              [_c("privacy-policy")],
              1
            ),
            _c(
              "b-tab-item",
              { attrs: { label: "Cookies" } },
              [_c("cookies-policy")],
              1
            ),
            _c(
              "b-tab-item",
              { attrs: { label: "Terms & Conditions" } },
              [_c("terms-and-conditions")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }