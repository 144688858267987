import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import PrivacyPolicy from '@/components/policy/PrivacyPolicy.vue';
import CookiesPolicy from '@/components/policy/CookiesPolicy.vue';
import TermsAndConditions from '@/components/policy/TermsAndConditions.vue';
var PolicyViewer = (function (_super) {
    __extends(PolicyViewer, _super);
    function PolicyViewer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], PolicyViewer.prototype, "aProperty", void 0);
    PolicyViewer = __decorate([
        Component({
            components: { TermsAndConditions: TermsAndConditions, CookiesPolicy: CookiesPolicy, PrivacyPolicy: PrivacyPolicy }
        })
    ], PolicyViewer);
    return PolicyViewer;
}(Vue));
export default PolicyViewer;
