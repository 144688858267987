import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var CookiesPolicy = (function (_super) {
    __extends(CookiesPolicy, _super);
    function CookiesPolicy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CookiesPolicy = __decorate([
        Component
    ], CookiesPolicy);
    return CookiesPolicy;
}(Vue));
export default CookiesPolicy;
