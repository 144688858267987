import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var TermsAndConditions = (function (_super) {
    __extends(TermsAndConditions, _super);
    function TermsAndConditions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TermsAndConditions = __decorate([
        Component
    ], TermsAndConditions);
    return TermsAndConditions;
}(Vue));
export default TermsAndConditions;
